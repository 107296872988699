import "./App.css";
import "antd/dist/antd.css";
import Portal from "./pages/Portal";
import Launcher from "./pages/Launcher";
import Oauth2 from "./pages/Oauth2";
import Oauth2Fail from "./pages/Oauth2Fail";
import Oauth2Success from "./pages/Oauth2Success";
import NotFoundPage from "./pages/404";
import React, { useState, useEffect } from "react";
import { Client, Account, AppwriteException } from "appwrite";
import { message, PageHeader, Button, Layout } from "antd";
import { Route, Routes, useNavigate } from "react-router-dom";

const { Header, Footer, Sider, Content } = Layout;

function App() {
  const baasclient = new Client();
  // Init your Web SDK
  baasclient
    .setEndpoint(process.env.REACT_APP_BAAS_END_POINT) // Your API Endpoint
    .setProject(process.env.REACT_APP_BAAS_PROJECT_ID); // Your project ID
  const baasaccount = new Account(baasclient);

  const [currentPage, setCurrentPage] = useState();
  const [currentUser, setCurrentUser] = useState(null);

  var navigate = useNavigate();

  let loginedPage = (
    <Routes>
      <Route
        exact
        path="*"
        element={
          <Portal
            navigate={navigate}
            baasclient={baasclient}
            baasaccount={baasaccount}
          />
        }
      />
      <Route
        exact
        path="/auth"
        element={<Oauth2 baasclient={baasclient} baasaccount={baasaccount} />}
      />
      <Route
        exact
        path="/auth/oauth2/success"
        element={<Oauth2Success updateCurrentUser={(e)=>{setCurrentUser(e)}} baasclient={baasclient} baasaccount={baasaccount}/>}
      />
      <Route
        exact
        path="/auth/oauth2/fail"
        element={<Oauth2Fail baasclient={baasclient} />}
      />
      <Route exact path="/launcher" element={<Launcher />} />
      <Route exact path="/404" element={<NotFoundPage />} />
    </Routes>
  );

  let logoutedPage = (
    <Routes>
      <Route
        exact
        path="*"
        element={<Oauth2 baasclient={baasclient} baasaccount={baasaccount} />}
      />
      <Route
        exact
        path="/auth/oauth2/fail"
        element={<Oauth2Fail baasclient={baasclient} />}
      />
      <Route exact path="/launcher" element={<Launcher />} />
      <Route exact path="/404" element={<NotFoundPage />} />
    </Routes>
  );

  useEffect(() => {
    // 获取用户信息，从而判断当前设备终端是否处于登录状态
    const promise = baasaccount.get();

    promise.then(
      (response) => {
        console.log(response); // Success
        setCurrentUser(response);
        setCurrentPage(loginedPage);
      },
      (error) => {
        console.log(error); // Failure
        if (error instanceof AppwriteException) {
          setCurrentUser(null);
          // not logged int
          // 未登录逻辑，显示登录及注册页面
          setCurrentPage(logoutedPage);
        } else {
          // TODO
        }
      }
    );
  }, []);

  let logOutBtn = (
    <Button
      key=""
      onClick={() => {
        let promise = baasaccount.deleteSessions();
        promise.then(
          (response) => {
            setCurrentUser(null);
            setCurrentPage(logoutedPage);
            message.info("已登出");
          },
          (error) => {
            message.error("登出失败");
            console.log(error);
          }
        );
      }}
      disabled={false}
    >
      登出
    </Button>
  );

  let style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  let styleContent = {
    marginTop: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  let styleHeader = {
    height: 5
  };

  return (
    <div style={style}>
      <Layout>
        <PageHeader
          title="钉钉智能路由"
          subTitle="鉴权"
          ghost="true"
          extra={[
            <span key="global-useName">{currentUser && currentUser.name}</span>,
            currentUser && logOutBtn,
          ]}
        ></PageHeader>
        <Header style={styleHeader}></Header>
        <Content style={styleContent}>{currentPage}</Content>
        <Footer style={style}>杭州大雅智堂信息系统有限公司@2022</Footer>
      </Layout>
    </div>
  );
}

export default App;
