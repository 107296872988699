import { Link } from "react-router-dom";
import {
  Form,
  FormGroup,
  Card,
  CardTitle,
  CardSubtitle,
  CardBody,
} from "reactstrap";
import "./index.css";
let Portal = (props) => {
  return (
    <div className={"divStyle"}>
      <Card>
        <CardBody>
          <CardTitle>
            <h2>关闭当前页面即可访问网络</h2>
          </CardTitle>
          <CardSubtitle className="text-muted">
            无法进入？请点击
            <Link to="/help">帮助 </Link>
          </CardSubtitle>
          <br />
          <Form>
            <FormGroup>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default Portal;
