import React from "react";

let Oauth2 = (props) => {
  return (
    <div className="App">
      <h1>认证失败!</h1>
      请联系钉钉管理员<a href="tel:18658808996">18658808996</a>确认是否有权访问本企业网络
    </div>
  );
};

export default Oauth2;
