import { Button, Result } from "antd";
import React from "react";

const NotFoundPage = () => (
  <Result
    status="404"
    title="404"
    subTitle="这应该不是您找的内容，当前页面并不存在。"
    extra={<Button type="primary" onClick={(e)=>{window.location.href="/"}}>回到首页</Button>}
  />
);

export default NotFoundPage;
