import {
  Form,
  FormGroup,
  Card,
  CardTitle,
  CardSubtitle,
  CardBody,
} from "reactstrap";
import "./index.css";
import { Link } from "react-router-dom";
import { DingdingOutlined } from "@ant-design/icons";

let Oauth2 = (props) => {
  let baasaccount = props.baasaccount;

  let style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div className={"divStyle"}>
      <Card>
        <CardBody>
          <CardTitle style={style}>
            <h2>
              <strong> 登 陆 </strong>
            </h2>
          </CardTitle>
          <CardSubtitle className="text-muted">
            <span style={{ fontSize: 10 }}>无法进入？请点击帮助。</span>
            <Link to="/help">帮助</Link>
          </CardSubtitle>
          <br />
          <div style={style}>
            <DingdingOutlined
              style={{ fontSize: "300%" }}
              onClick={() => {
                baasaccount.createOAuth2Session(
                  "idaas",
                  process.env.REACT_APP_OAUTH2_SUCCESS_REDIRECT_DOMAIN+"/auth/oauth2/success"+window.location.search,
                  process.env.REACT_APP_OAUTH2_SUCCESS_REDIRECT_DOMAIN+"/auth/oauth2/failure",
                  []
                );
              }}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Oauth2;
