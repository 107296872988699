import React, { useState, useEffect, useRef } from "react";
import { message, Col, Row, Button } from "antd";
import { Functions } from "appwrite";
const queryString = require("query-string");

let Oauth2 = (props) => {
  const updateCurrentUser = props.updateCurrentUser;
  const baasaccount = props.baasaccount;
  const baasclient = props.baasclient;
  const baasFunctions = new Functions(baasclient);

  const [showManuBtn, SetShowManuBtn] = useState(false); //倒计时时间
  const [time, setTime] = useState(5); //倒计时时间
  const timeRef = useRef(); //设置延时器

  // 此时参数中缺少custom_name参数，需要主动获取后放入params中
  let params = queryString.parse(window.location.search);

  let gotoRouterAuthUrl = () => {
    // 请求爱快的跳转链接
    const promise = baasFunctions.createExecution(
      process.env.REACT_APP_BAAS_ROUTER_REDIRECT_URL_FUC_ID,
      JSON.stringify(params),
      false
    );

    promise.then(
      function (response) {
        console.log(response); // Success
        // 解析跳转链接，跳转到路由器
        let data = JSON.parse(response.response);
        let toUrl = data.passbyurl;
        // 拿到链接后就登出，避免下次打开爱快鉴权页面，依然是登录状态

        let promise = baasaccount.deleteSessions();
        promise.then(
          (response) => {
            window.location.href = toUrl;
          },
          (error) => {
            message.error("登出失败");
            console.log(error);
          }
        );
      },
      function (error) {
        message.error(error);
        console.log(error); // Failure
      }
    );
  };

  //倒计时
  useEffect(() => {
    //如果设置倒计时且倒计时不为0
    if (time && time !== 0)
      timeRef.current = setTimeout(() => {
        setTime((time) => time - 1);
      }, 1000);
    else gotoRouterAuthUrl();
    //清除延时器
    return () => {
      clearTimeout(timeRef.current);
    };
  }, [time]);

  useEffect(() => {
    // 因为createEmailSession拿不到用户名称信息，所以需要用get方法调用
    // 登陆后，获取用户名称信息
    const promise = baasaccount.get();
    promise.then(
      (response) => {
        console.log(response); // Success
        updateCurrentUser(response);
        // 将当期登陆用户的姓名，写入到即将获取跳转链接的入参中
        // TODO
        // 注意，上面倒计时结束后获取跳转链接，跟这个方法存在理论顺序错误
        params["custom_name"] = response.name;
        message.success(`鉴权通过`);
      },
      (error) => {
        SetShowManuBtn(true);
        console.log(error);
      }
    );
  }, []);

  return (
    <div className="App">
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
      >
        <Col span={24} justify="center" align="middle">
          {showManuBtn ? (
            <Button
              onClick={(e) => {
                gotoRouterAuthUrl();
              }}
            >
              {"手动点击"}
            </Button>
          ) : (
            <div>
              <span>{"鉴权通过, 即将跳转" + time}</span>
              <span>{"跳转成功后，请关闭本窗口"}</span>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Oauth2;
